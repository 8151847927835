import { useSelector } from "react-redux";
import { RootState } from "./store";

export const usePosterReducers = () => {
    return useSelector((state: RootState|any) => {
        return ({
            ...state?.combinedReducer?.request,
            user_data:state?.combinedReducer?.user_data,
            reviews:state?.combinedReducer?.reviews,
            // headings:state?.headings,
        })
    });
  };