/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { MouseEventHandler, useEffect } from 'react';
import ReactDOM from 'react-dom';
import Button from '../Button';
import { useDispatch } from 'react-redux';
import { usePosterReducers } from '../../redux/usePoster';

interface ModalProps {
  show: boolean;
  onSubmit?: Function;
  handleSubmit?: Function;
  onHide?: Function;
  multiSubmit?: any;
  className?: string;
  headerClassName?: string;
  contentClassName?: string;
  children?: React.ReactNode;
  Title?: string | React.ReactNode;
  closeButton?: boolean;
  ConfirmModal?: any;
  action_loader?:string[];
  reset?:any;
}

const   Modal: React.FC<ModalProps> & {
  Header: React.FC<{ closeButton?: boolean; onClose?: MouseEventHandler<HTMLButtonElement>; children?: React.ReactNode; className?:string }>;
  Body: React.FC<{ children?: React.ReactNode; className?: string }>;
  Footer: React.FC<{ children?: React.ReactNode }>;
} = (props) => {
  const { show, onHide=()=>{}, className, contentClassName, children } = props;

  const {socketResponse} = usePosterReducers();
  const dispatch = useDispatch();
  useEffect(()=>{
    
    if(socketResponse?.request?.demo?.modal_load){
      props?.reset({})
    }
  },[socketResponse])
  const callCancelFunction = () => {
    if(props?.ConfirmModal?.callBackModal){
      props?.ConfirmModal?.callBackModal?.();
    }
    if(props?.ConfirmModal){
      // dispatch(setShowModalPopup());
    }
    onHide();
  };
  const handleFormSubmit = (e: any,label?:string) => {
    e.preventDefault();
    if (props.handleSubmit && props.onSubmit) {
      props.handleSubmit(props.onSubmit)();
    }else if(props.ConfirmModal.callBackModal && props.handleSubmit){
      props.handleSubmit(props.ConfirmModal.callBackModal)();
    }
    else if(props?.multiSubmit?.length>0) {
      props?.multiSubmit.filter((item:any) => {
        if(item.label===label && props.handleSubmit){
          props.handleSubmit(item.callBack)();
        }
      });
    }
  };
  if (!show) return null;

  return ReactDOM.createPortal(
    <div className={`modal-overlay ${className}`}>
      <div className={`modal-content ${contentClassName}`}>
        {props.Title && (
          <Modal.Header closeButton={props.closeButton} className={`xl:text-xl-text-size 2xl:text-2xl-text-size ${props?.headerClassName}`} onClose={()=>onHide()}>
            {props.Title}
          </Modal.Header>
        )}
        {children}
        {(props.ConfirmModal?.buttonSuccess || props.ConfirmModal?.buttonCancel) && (
          <Modal.Footer>
            {props.ConfirmModal?.buttonCancel && (
              <button
                className="py-2.5 px-5 ms-3 2xl:text-2xl-text-size xl:text-xl-text-size font-medium border hover:bg-slate-100 rounded-lg"
                onClick={callCancelFunction}
              >
                {props.ConfirmModal.buttonCancel}
              </button>
            )}
            {props.ConfirmModal?.buttonSuccess && (
              <Button
                label={props.ConfirmModal.buttonSuccess}
                className='button btn-primary-1'
                onClick={handleFormSubmit}
                // isLoading={modalLoader === ""?false:true}
                // disabled={modalLoader === ""?false:true}
              />
            )}
          </Modal.Footer>
        )}
      </div>
    </div>,
    document.body // Append modal to the body
  );
};

// Modal.Header Component
Modal.Header = ({ closeButton, onClose, children, className }) => (
  <div className={`modal-header ${className}`}>
    {children}
    {closeButton && <button className="modal-close-button" onClick={onClose}>&times;</button>}
  </div>
);

// Modal.Body Component
Modal.Body = ({ children, className }) => (
  <div className={`modal-body ${className}`}>
    {children}
  </div>
);

// Modal.Footer Component
Modal.Footer = ({ children }) => (
  <div className="modal-footer px-2">
    {children}
  </div>
);

export default Modal;
