/* eslint-disable eqeqeq */
export const callInputClassName = (size:string, props:any) =>{
    const className:any = {
        input:" px-3 py-2 w-full",
        inputGroup:"w-full border-secondary",
        leftLabel:"flex items-center justify-center px-[10px] py-[7px]",
        rightLabel:"flex items-center justify-center px-[10px] py-[7px]",
    }
    if(size === "md"){
        className.input = ` ${className.input} `
    }
    if(size === "sm"){
        className.input = `w-full px-3 py-1`;
        className.leftLabel = "flex items-center justify-center px-[7px] py-[5px]";
        className.rightLabel = "flex items-center justify-center px-[7px] py-[5px]";
    }
    if(size === "lg"){
        className.input = `w-full px-3 py-[10.46px]`;
    }
    if(props == "primary-secondary"){
        className.inputGroup = `w-full border-primary-s`;
    }
    return className;
}
export const callButtonClassName = (size:string, Variant?:string)=>{
    const className:any = {
    }
    let padding = "px-3 py-2";
    if(size === "nm"){
        className.className = `rounded-sm ${padding}`
        className.icon = ``
    }
    if(size === "sm"){
        className.className = `md:min-h-[30px] 2xl:[33px] 2xl:text-lg rounded-xsm px-[10px] py-[3px]`
        className.icon = `sm`
    }
    
    return className;
}