/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import Modal from './Modal';
import { useModalData, useWebSocket } from '../../WebSocket/socketContext';

interface IPreviewModal{
  PreviewPopup?: {
    show?: string;
    data?:any
  };
}
export default function PreviewModal(props:IPreviewModal) {
  const { PreviewPopup } = useModalData()
  const dispatch = useDispatch()
  const {setPreviewPopup} = useModalData()

  useEffect(()=>{
    onClose();
  },[])
  const onClose = () =>{
    setPreviewPopup()
  }
  if(PreviewPopup?.show !== "PREVIEW"){
    return null;
  }
  return (
    <React.Fragment>
      <Modal
        show={true}
        contentClassName='max-w-[calc(100%-90px)] h-full p-12 confirm-modal'
        className='p-2 px-4'
        onHide={onClose}
        Title="Preview"
        closeButton
      >
        <Modal.Body className={'max-w-[100%] h-full'}>
            {/* <iframe src={PreviewPopup?.data?.url} width={"100%"} height={'100%'} /> */}
        </Modal.Body>
      </Modal>
    </React.Fragment>
  )
}
