import { combineReducers, configureStore,  } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { userDataReducers } from "./modules/user_data";
import { IUserRes } from "./modules/user_data/types";
import requestReducers from "./modules/request/reducer";
import reviewsReducer, { ReviewsState } from './modules/learnersNotification/reducer';


// export interface ApplicationState {
//   user_data: IUserRes;
//   learnersNotification: ReviewsState;
// }

export interface RootState {
  combinedReducer: {
    user_data: IUserRes;
    request: ReturnType<typeof requestReducers>;
    reviews: ReviewsState;
  };
}
const combinedReducer:any = combineReducers({
  user_data: userDataReducers,
  request: requestReducers,
  reviews: reviewsReducer,
});

// const rootReducer = combineReducers({
//   combinedReducer: combinedReducer
// });



const rootReducer = combineReducers({
  combinedReducer: persistReducer(
    { key: "chathub-store", storage, blacklist: ["uiReducer"] },
    combinedReducer,
  ),
});



export function makeStore() {
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: ["persist/PERSIST"], // Ignore serializability check for specific actions
        },
      }),
  });

  const persistor = persistStore(store);
  return { store, persistor };
}

const { store, persistor } = makeStore();
// export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export { store, persistor };
