/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import Button from "./Button";
import Images from "./Image";
import { Variant } from "./constants/static";

interface IconProps {
  className?: string;
  buttonClassName?: string;
  size?: string;
  variant?: 'outline' | "primary" | "primary-1";
  auth?: boolean;
  rounded?: boolean;
  disabled?: boolean;
  onClick?: (e: any) => void;
  loading?: boolean;
  attrIcon?: any;
  image?: boolean;
  style?: any;
  button?: boolean;
}

const Icon: React.FC<IconProps> = (props) => {


  const onClick = (e: any) => {
    if (props?.onClick) {
      e.preventDefault();
      e.stopPropagation();
      props?.onClick(e);
    }
  };
  const IconData = () => {
    if (props?.image) {
      return (
        <i
          onClick={onClick}
          className={`common_icon_image ${props?.className} ${props?.rounded&&"rounded-full"}  ${!props?.button && props?.variant && Variant[props?.variant]}`}
        >
          <Images src={props?.attrIcon || ""} auth={props?.auth} />
        </i>
      );
    }
    return (
      <i
        onClick={onClick}
        style={{ ...props?.style, "--icon-url": `url(${props?.attrIcon})` }}
        className={`common_icon ${props?.className} ${props?.rounded&&"rounded-full"} ${!props?.button && props?.variant && Variant[props?.variant]}`}
        attr-icon={props?.attrIcon}
      />
    );
  };

  const ButtonView = () => {
    return (
      <Button
        disabled={props?.disabled}
        onClick={onClick}
        className={`btn-icon ${props?.buttonClassName} ${props?.rounded&&"rounded-full"} btn-${props?.size} `}
        children={IconData()}
        variant={props?.variant}
      />
    );
  };

  if (props?.button) {
    return ButtonView();
  }
  return IconData();
};

Icon.defaultProps = {
  className: "",
  buttonClassName: "",
  size: "",
  auth: false,
  rounded: false,
  disabled: false,
  loading: false,
  attrIcon: "",
  image: false,
  button: false,
  style: {},
};

export default Icon;
