import { MouseEventHandler, ReactNode, useState } from "react";
import { Variant } from "./constants/static";
import Icon from "./Icon";
import { callButtonClassName } from "./utils/styleName";

type IButtonTypes = {
  label?: any;
  className?: string;
  size?: "sm" | "lg" | "md" | "nm";
  variant?: "outline" | "primary" | "primary-1";
  onClick?: any;
  type?: "submit" | "reset" | "button" | undefined;
  icon?: any;
  disabled?: boolean;
  isLoading?: boolean;
  children?: string | ReactNode;
};
const Button = (prop: IButtonTypes) => {
  const { size = "nm" } = prop
  const [disabled, setDisabled] = useState(false)
  const onClick = (e: MouseEventHandler | any) => {
    e.preventDefault();
    e.stopPropagation();
    if (prop?.onClick) {
      setDisabled(true);
      if (!prop.disabled && !prop.isLoading) {
        prop?.onClick(e);
      }
      setTimeout(() => setDisabled(false), 500)
    }
  }
  const className = callButtonClassName(size)
  return (
    <button
      disabled={prop.disabled || disabled}
      type={`${prop.type ? prop.type : "button"}`}
      className={`flex items-center justify-center ${prop.className} ${className?.className} text-sm transition-colors duration-300 ${prop?.variant ? Variant[prop?.variant]?.className : ""}`}
      onClick={onClick}
    >
      <div className="flex justify-center items-center">
        {prop.icon && <Icon attrIcon={prop.icon} size={prop?.size} className={`${className?.icon} ${prop?.variant ? Variant[prop?.variant]?.icon : ""}`} />}
        {prop.children && <span className={`flex ${prop.icon ? "pl-2" : ""}`}>{prop.children}</span>}
        {prop.label && <p>{prop.label}</p>}
      </div>
    </button>
  );
};

export default Button;
