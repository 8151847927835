
// import Dashboard from "../Dashboard";

export const App_url = {
  link: {
    Dashboard: "/",
    Learner: "/learner",
    AddLearner: "/learners/add",

    Admin_Profile : '/admin/profile',
     adminProfileEdit : '/admin/profile/profile-edit',
     adminSign : '/admin/profile/sign-upload',
    LearnerInformation: "/learner-information",
     LearnerDetails: "/learner-information/learner-details",
      ViewBasicDetails: "/learner/learner-information/learner-details/basic-detail",
      Ethnicity: "/learner/learner-information/learner-details/ethnicity",
      LearningSupport: "/learner/learner-information/learner-details/learning-support",
      PriorEducation: "/learner/learner-information/learner-details/prior-education",
      Employment: "/learner/learner-information/learner-details/employment",
      HouseholdSituation: "/learner/learner-information/learner-details/household-situation",
      DataProtection: "/learner/learner-information/learner-details/data-protection",
      OtherDetails: "/learner/learner-information/learner-details/other-details",
      LearnerInformationStatus: "/learner/learner-information/learner-details/status",

     SkillScan: "/learner/learner-information/skill-scan",
      InitialSkillScan: "/learner/learner-information/skill-scan/initial-skill-scan",
      MidSkillScan: "/learner/learner-information/skill-scan/mid-skill-scan",
      FinalSkillScan: "/learner/learner-information/skill-scan/final-skill-scan",
      SkillScanStatus: "/learner/learner-information/skill-scan/status",
      SkillScanStatistics : "/learner/learner-information/skill-scan/statistics",
    
     InitialAssessment: "/learner/learner-information/initial-assessment",
      AssessmentResult: "/learner/learner-information/initial-assessment/assessment-result",
      
     ProgramAllocation: "/learner/learner-information/program-allocation",
      ProgramAllocationInformation: "/learner/learner-information/program-allocation/program-allocation-information",

     LearningPlanLearnerInfo: "/learner/learner-information/learning-plan",

     Compliance: "/learner/learner-information/compliance",
      Apprenticeship: "/learner/learner-information/compliance/apprenticeship",
      Commitment: "/learner/learner-information/compliance/commitment",
      Individual: "/learner/learner-information/compliance/individual",
      WrittenAgreement: "/learner/learner-information/compliance/written-agreement",      
      ComplianceStatus: "/learner/learner-information/compliance/compliance-status",

    DeliveryInformation: "/learner/delivery-information",
      LearningPlan: "/learner/delivery-information/learning-plan",
      OTJHRecord: "/learner/delivery-information/otjh-record",
      Attendance: "/learner/delivery-information/attandance",
      Standard : '/learner/delivery-information/attandance/standard',
      Progress: "/learner/delivery-information/progress/standard",
      // Progress: "/delivery-information/progress",
      Reviews: "/learner/delivery-information/reviews",
      MonthlyProgressReport : '/learner/delivery/progress/monthly-progress-report',
      
    Summary: "/summary",
   
    // Delivery
    Delivery_OTJHRecord:"/delivery" ,
    attendance : '/delivery/attendance/attendance',
    listOfLectures : '/delivery/attendance/list-of-lectures',
    addLectures : '/delivery/attendance/add-lectures',
    addProgress : '/delivery/progress/add-progress',
    deliveryProgress: '/delivery/progress/delivery-progress',
    DeliveryReviews: '/delivery/progress/delivery-reviews',




    Users: "/users",
    Admin: "/users/admin",
    CreateAdmin: "/users/admin/create",
    Tutor: "/users/tutor",
    CreateTutor: "/users/create-tutor",
    Employer: "/users/employer",
    CreateEmployer: "/users/create-employer",
    EPA: "/users/epa",
    CreateEPA: "/users/create-EPA",
    IQA: "/users/iqa",
    CreateIQA: "/users/create-IQA",
    Referrer: "/users/referrer",
    CreateReferrer: "/users/create-referrer",
    Group: "/users/group",
    CreateGroup: "/users/create-group",
    Program: "/program",
    AddStandardProgram: "/program/standard/add",
    AddDiplomaProgram: "/program/diploma/add",
    AddFSProgram: "/program/functional-skill/add",

    Delivery: "/delivery",



     // Learners flow

    learners: "/learners",
    Learners: "/learners/onboarding",
    learnerInformation : '/learners/learners-information',
    skillScan :'/learners/skill-Scan',
    learnerContactUs : '/learners/contact-us',
    // Learners: "/learners",
    // learnerInformation : '/learners-information',
    learnerNotification : '/learners/notification',
    // learnerContactUs : '/learners-contact-us',
           
    learnersCompliance: "/learners/compliance",
    learnersApprenticeship: "/learners/compliance/apprenticeship-agreement",
    learnersCommitment: "/learners/compliance/commitment-statement",
    learnersIndividual: "/learners/compliance/individual-learner-record",
    learnersWrittenAgreement: "/learners/compliance/written-agreement",
    learnersLearningPlan: "/learners/learning-plan/",
    learnersReviews: "/learners/reviews/",
    learnersOtjhRecord: "/learners/OTJH-record/",
    learnersAttendance: "/learners/attendance/",
    learnersProgress: "/learners/progress/Standard",
    learnersSummary: "/learners/summary/",


    Notifications: "/notifications",
    ContactUs: "/contact-us",
    ViewContactUs: "/contact-us/contact-us-details",
    SignIn: "/",
    SignUp: "/sign-up",
    ForgetPassword: "/forget-password",
    EnterOTP: "/forget-password/otp",
    ResetPassword: "/reset-password",    
  },

  EmployerPanelLink: {
    employee_Profile : '/employee/profile',
     employeeProfileEdit : '/employee/profile/profile-edit',
     employeeSign : '/employee/profile/sign-upload',
    Dashboard: "/",
    Learner: "/employer/learner",
    Notifications: "/employer/notifications",
    ContactUS:"/employer/contact-us",
    AddLearner: "/employer/learners/add",
    LearnerInformation: "/employer/learner-information",
     LearnerDetails: "/employer/learner-information/learner-details",
      ViewBasicDetails: "/employer/learner-information/learner-details/basic-detail",
      Employment: "/employer/learner-information/learner-details/employment",
     SkillScan: "/employer/learner-information/skill-scan",
      InitialSkillScan: "/employer/learner-information/skill-scan/initial-skill-scan",
      MidSkillScan: "/employer/learner-information/skill-scan/mid-skill-scan",
      FinalSkillScan: "/employer/learner-information/skill-scan/final-skill-scan",
      SkillScanStatistics: "/employer/learner-information/skill-scan/statistic-skill-scan",
      Compliance: "/employer/learner-information/compliance/apprenticeship",
      Commitment: "/employer/learner-information/compliance/commitment",
      Individual: "/employer/learner-information/compliance/individual",
      writtenAgreement: "/employer/learner-information/compliance/written-agreement",
    DeliveryInformation: "/employer/delivery-information",
      OTJHRecord: "/employer/delivery-information/otjh-record",
      Standard : '/employer/delivery-information/attandance/standard',
      Progress: "/employer/delivery-information/progress",
      Reviews: "/employer/delivery-information/reviews",
    Summary: "/employer/summary",
    
  },
  TutorPanelLink: {
    employee_Profile : '/tutor/profile',
     employeeProfileEdit : '/tutor/profile/profile-edit',
     employeeSign : '/tutor/profile/sign-upload',
    Dashboard: "/",
    Learner: "/tutor/learner",
    Delivery: "/tutor/delivery/otjh-record",
    Notifications: "/tutor/notifications",
    ContactUS:"/tutor/contact-us",
    AddLearner: "/employer/learners/add",
    LearnerInformation: "/tutor/learner-information",
     LearnerDetails: "/tutor/learner-information/learner-details",
      ViewBasicDetails: "/tutor/learner-information/learner-details/basic-detail",
     SkillScan: "/tutor/learner-information/skill-scan",
      InitialSkillScan: "/tutor/learner-information/skill-scan/initial-skill-scan",
      MidSkillScan: "/tutor/learner-information/skill-scan/mid-skill-scan",
      FinalSkillScan: "/tutor/learner-information/skill-scan/final-skill-scan",
      SkillScanStatistics: "/tutor/learner-information/skill-scan/statistic-skill-scan",
     InitialAssessment: "/tutor/learner-information/initial-assessment",
      AssessmentResult: "/tutor/learner-information/initial-assessment/assessment-result",
    DeliveryInformation: "/tutor/delivery-information",
      LearningPlan: "/tutor/delivery-information/learning-plan",
      OTJHRecord: "/tutor/delivery-information/otjh-record",
      Standard : '/tutor/delivery-information/attandance/standard',
      Progress: "/tutor/delivery-information/progress",
      Reviews: "/tutor/delivery-information/reviews",
    Summary: "/tutor/summary",
    Delivery_OTJHRecord:"/tutor/delivery/otjh-record" ,
      attendance : '/tutor/delivery/attendance1',
      listOfLectures : '/tutor/delivery/list-of-lectures',
      addLectures : '/tutor/delivery/add-lectures',
      addProgress : '/tutor/delivery/add-progress',
      deliveryProgress: '/tutor/delivery/delivery-progress/standard',
      DeliveryReviews: '/tutor/delivery/delivery-reviews',
      MonthlyProgressReport : '/tutor/delivery/delivery-progress/monthly-progress-report',
      tutorAttendance : '/tutor/delivery/standard',

  },

  IQAPanelLink: {
    Learner: "/iqa-panel/learner",
    Delivery: "/iqa-panel/delivery",
    LearnerInformation: "/iqa-panel/learner-information",
     LearnerDetails: "/iqa-panel/learner-information/learner-details",
      ViewBasicDetails: "/iqa-panel/basic-detail",
     SkillScan: "/iqa-panel/skill-scan",
      SkillScanStatistics: "/iqa-panel/skill-scan/statistic-skill-scan",
     DeliveryInformation: "/iqa-panel/delivery-information",
      Reviews: "/iqa-panel/reviews",
    DeliveryFlow: "/iqa-panel/delivery/otjh-record", 
     deliveryProgress: '/iqa-panel/delivery/standard',
    ContactUS:"/iqa-panel/contact-us",
  },

  image: {
    profile : `${window.location.origin}/assets/image/profile.svg`,
    company_logo : `${window.location.origin}/assets/logo/company.svg`,
    dashboard_img : `${window.location.origin}/assets/image/dashboard-img.svg`,
    Learner: `${window.location.origin}/assets/image/Learners.svg`,
    Users: `${window.location.origin}/assets/image/Users.svg`,
    Admin: `${window.location.origin}/assets/image/Users.svg`,
    Tutor: `${window.location.origin}/assets/image/Users.svg`,
    Employer: `${window.location.origin}/assets/image/Users.svg`,
    EPA: `${window.location.origin}/assets/image/Users.svg`,
    IQA: `${window.location.origin}/assets/image/Users.svg`,
    Group: `${window.location.origin}/assets/image/Users.svg`,
    Referre: `${window.location.origin}/assets/image/Users.svg`,
    Delivery: `${window.location.origin}/assets/logo/Delivery.svg`,
    Program: `${window.location.origin}/assets/logo/Program.svg`,
    Banner: `${window.location.origin}/assets/sidebar/Banner.webp`,
    slider: `${window.location.origin}/assets/sidebar/slider.svg`,
    info: `${window.location.origin}/assets/info.webp`,
    Logo: `${window.location.origin}/assets/sidebar/Logo.webp`,
    Down: `${window.location.origin}/assets/image/Down.svg`,
    UserDropdown: `${window.location.origin}/assets/image/UserDropdown.svg`,
    Notification: `${window.location.origin}/assets/image/Notification.svg`,
    LoginBanner: `${window.location.origin}/assets/image/LoginBanner.webp`,
    filter: `${window.location.origin}/assets/image/LoginBanner.webp`,
    add: `${window.location.origin}/assets/image/add.svg`,
    AngleDown: `${window.location.origin}/assets/image/AngleDown.svg`,
    ArrowDown: `${window.location.origin}/assets/image/ArrowDown.svg`,
    arrowleft: `${window.location.origin}/assets/image/arrowleft.svg`,
    Reset: `${window.location.origin}/assets/image/Reset.svg`,
    calendar: `${window.location.origin}/assets/image/calendarIcon.svg`,
    SortList: `${window.location.origin}/assets/image/SortList.svg`,
    FilterSort: `${window.location.origin}/assets/image/FilterSort.svg`,
    Info: `${window.location.origin}/assets/image/Info.svg`,
    Timer: `${window.location.origin}/assets/image/Timer.svg`,
    FileAlt: `${window.location.origin}/assets/image/FileAlt.svg`,
    FileDownload: `${window.location.origin}/assets/image/FileDownload.svg`,
    eye: `${window.location.origin}/assets/image/eye.svg`,
    ArrowUpRight: `${window.location.origin}/assets/image/ArrowUpRight.svg`,
    ArrowRight: `${window.location.origin}/assets/image/ArrowRight.svg`,
    Edit: `${window.location.origin}/assets/image/Edit.svg`,
    Download: `${window.location.origin}/assets/image/Download.svg`,
    schedule: `${window.location.origin}/assets/image/schedule.svg`,
    Delete: `${window.location.origin}/assets/image/Delete.svg`,
    archive: `${window.location.origin}/assets/image/archive.svg`,
    unarchive: `${window.location.origin}/assets/image/unarchive.svg`,
    addIcon: `${window.location.origin}/assets/image/addIcon.svg`,
    Search: `${window.location.origin}/assets/image/Search.svg`,
    Statistic: `${window.location.origin}/assets/image/Statistic.svg`,
    Phone: `${window.location.origin}/assets/image/Phone.svg`,
    NotifyIcon: `${window.location.origin}/assets/image/BellSimpleRinging.svg`,
    Envelope: `${window.location.origin}/assets/image/envelope.svg`,
    Email: `${window.location.origin}/assets/image/EnvelopeSimple.svg`,
    Plus:`${window.location.origin}/assets/image/Plus.svg`,
    PhoneCall: `${window.location.origin}/assets/image/PhoneCall.svg`,
    confirmPopupIcons1: `${window.location.origin}/assets/image/confirmPopupIcons1.svg`,
    confirmPopupIcons2: `${window.location.origin}/assets/image/confirmPopupIcons2.svg`,
    confirmPopupIcons3: `${window.location.origin}/assets/image/confirmPopupIcons3.svg`,
    confirmPopupIcons4: `${window.location.origin}/assets/image/confirmPopupIcons4.svg`,
    Upload: `${window.location.origin}/assets/image/Upload.svg`,
    Download1: `${window.location.origin}/assets/image/Download1.svg`,
    FileDoc: `${window.location.origin}/assets/image/FileDoc.svg`,
    pdfIcon: `${window.location.origin}/assets/image/pdfIcon.png`,
    PencilSimpleLine: `${window.location.origin}/assets/image/PencilSimpleLine.svg`,
    clone: `${window.location.origin}/assets/image/clone.svg`,

    onboarding : `${window.location.origin}/assets/image/onboarding.svg`,
    skillScan : `${window.location.origin}/assets/image/skillScan.svg`,
    complinceIcon :`${window.location.origin}/assets/image/compliance.svg`,
    learnerPlan :`${window.location.origin}/assets/image/learningPlan.svg`,
    review :`${window.location.origin}/assets/image/review.svg`,
    otjhLearner :`${window.location.origin}/assets/image/otjhLearner.svg`,
    attendance :`${window.location.origin}/assets/image/attendanceLearner.svg`,
    progress :`${window.location.origin}/assets/image/progressLearner.svg`,

    ethnicityICon:`${window.location.origin}/assets/Icons/Ethnicity.svg`,
    apprenticeshipLogo:`${window.location.origin}/assets/image/apprenticeshipLogo.svg`,
    ethnicityIcon:`${window.location.origin}/assets/Icons/Ethnicity.svg`,
    learnerInformationIcon:`${window.location.origin}/assets/Icons/learnerInformation.svg`,
    DataProtectionIcon:`${window.location.origin}/assets/Icons/DataProtection.svg`,
    PriorEducationIcon:`${window.location.origin}/assets/Icons/PriorEducation.svg`,
    LearningsupportneedsIcon:`${window.location.origin}/assets/Icons/Learningsupportneeds.svg`,
    HouseholdsituationIcon:`${window.location.origin}/assets/Icons/Householdsituation.svg`,
    EmploymentSectionIcon:`${window.location.origin}/assets/Icons/EmploymentSection.svg`,
    SkillIcon:`${window.location.origin}/assets/Icons/Skill.svg`,
    BehaviourIcon:`${window.location.origin}/assets/Icons/Behaviour.svg`,
    KnowledgeIcon:`${window.location.origin}/assets/Icons/Knowledge.svg`,
    ApprenticeshipAgreementIcon:`${window.location.origin}/assets/Icons/ApprenticeshipAgreement.svg`,
    CommitmentStatementIcon:`${window.location.origin}/assets/Icons/CommitmentStatement.svg`,
    IndividualLearnerRecordIcon:`${window.location.origin}/assets/Icons/IndividualLearnerRecord.svg`,
    WrittenAgreementIcon:`${window.location.origin}/assets/Icons/WrittenAgreement.svg`,
    PlusCircle: `${window.location.origin}/assets/image/PlusCircle.svg`,
    skillFundingLogo : `${window.location.origin}/assets/logo/skill-funding.svg`,
    europeanUnionLogo : `${window.location.origin}/assets/logo/EuropeanUnion.svg`,
    
    Bgcontactus: `${window.location.origin}/assets/image/Bgcontactus.webp`,
    percentSymbol: `${window.location.origin}/assets/image/percentSymbol.svg`,
  }
}
export default App_url;

export const SidebarList = [
  {
    title: "Learners",
    route: App_url.link.Learner,
    icon: App_url.image.Learner,
    classIcon: "bg-[linear-gradient(270deg,_var(--primary-300)_5.71%,_var(--primary-400)_62.77%)]",
    className: "bg-[linear-gradient(270deg,_rgba(81,_114,_231,_0)_5.71%,_var(--primary-400)_62.77%)]",
    // permission: dashboardPermission,
  },
  {
    title: "Users",
    route: App_url.link.Admin,
    icon: App_url.image.Admin,
    classIcon: "bg-[linear-gradient(134.67deg,_var(--pink-400)_1.75%,_var(--pink-400)_100%)]",
    className: "bg-[linear-gradient(270deg,_rgba(81,_114,_231,_0)_5.71%,_var(--pink-400)_62.77%)]"
    //   permission: customerServicePermission,
  },
  {
    title: "Delivery",
    route: App_url.link.Delivery,
    icon: App_url.image.Delivery,
    classIcon: "bg-[linear-gradient(152.06deg,_#66C6E4_17.33%,_#408595_100%)]",
    className: "bg-[linear-gradient(270deg,_rgba(81,_114,_231,_0)_5.71%,_#408595_62.77%)]"
  },
  {
    title: "Program",
    route: App_url.link.Program,
    icon: App_url.image.Program,
    classIcon: "bg-[linear-gradient(135.2deg,_#7EAF7A_3.19%,_#679258_100%)]",
    className: "bg-[linear-gradient(270deg,_rgba(81,_114,_231,_0)_5.71%,_#679258_62.77%)]"
    //   permission: supplierServicePermission,
  },
  {
    title: "Notifications",
    route: App_url.link.Notifications,
    icon: App_url.image.NotifyIcon,
    classIcon: "bg-[linear-gradient(152.06deg,_#906BAC_17.33%,_#734D99_100%)]",
    className: "bg-[linear-gradient(270deg,_rgba(81,_114,_231,_0)_5.71%,_#734D99_62.77%)]"
    //   permission: supplierServicePermission,
  },
  {
    title: "Contact Us",
    route: App_url.link.ContactUs,
    icon: App_url.image.Phone,
    classIcon: "bg-[linear-gradient(152.06deg,_#F09449_17.33%,_#FC883F_100%)]",
    className: "bg-[linear-gradient(270deg,_rgba(81,_114,_231,_0)_5.71%,_#FC883F_62.77%)]"
    //   permission: supplierServicePermission,
  },
  {
    title: "Onboarding",
    route: App_url.link.Learners, // Add the route for Onboarding
    icon: App_url.image.onboarding,
    // classIcon: "bg-[linear-gradient(270deg,_var(--primary-300)_5.71%,_var(--primary-400)_62.77%)]",
    // className: "bg-[linear-gradient(270deg,_rgba(81,_114,_231,_0)_5.71%,_var(--primary-400)_62.77%)]"
    classIcon : 'bg-[linear-gradient(270deg,_var(--primary-300)_5.71%,_var(--primary-400)_62.77%)]',
    className : 'text-black',
    permissions : 'Learners'
  },
  {
    title: "Skill Scan",
    route: App_url.link.skillScan, // Add the route for Onboarding
    icon: App_url.image.skillScan,
    classIcon: "bg-[linear-gradient(152.06deg,_#FB7CFF_17.33%,_#E137FF_100%)]",
    className : 'text-black',
    permissions : 'Learners'
  },
  {
    title: "Compliance",
    route: App_url.link.learnersCompliance, // Add the route for Onboarding
    icon: App_url.image.complinceIcon,
    classIcon: "bg-[linear-gradient(152.06deg,_#F09449_17.33%,_#FC883F_100%)]",
    className : 'text-black',
    permissions : 'Learners'
  },
 
  {
    title: "Learning Plan",
    route: App_url.link.learnersLearningPlan,
    icon: App_url.image.learnerPlan,
    classIcon: "bg-[linear-gradient(152.06deg,_#CC56FF_17.33%,_#8E3DFF_100%)]",
    className: "text-black",
    permissions : 'Learners'
  },
  {
    title: "Reviews",
    route: App_url.link.learnersReviews,
    icon: App_url.image.review,
    classIcon: "bg-[linear-gradient(270deg,_#3AA6FF_5.71%,_#308AFF_62.77%)]",
    className: "text-black",
    permissions : 'Learners'
  },
  {
    title: "OTJH Record",
    route: App_url.link.learnersOtjhRecord,
    icon: App_url.image.otjhLearner,
    classIcon: "bg-[linear-gradient(153deg,_#EF3AFF_45.71%,_#A91DFA_100%)]",
    className: "text-black",
    permissions : 'Learners'
  },
  {
    title: "Attendance",
    route: App_url.link.learnersAttendance,
    icon: App_url.image.attendance,
    classIcon: "bg-[linear-gradient(150deg,_#77DD18_40.71%,_#1BC649_100%)]",
    className: "text-black",
    permissions : 'Learners'
  },
  {
    title: "Progress",
    route: App_url.link.learnersProgress,
    icon: App_url.image.progress,
    classIcon: "bg-[linear-gradient(152.06deg,_#FFB956_17.33%,_#FF9524_100%)]",
    className: "text-black",
    permissions : 'Learners'
  },
  {
    title: "Notification",
    route: App_url.link.learnerNotification,
    icon: App_url.image.NotifyIcon,
    classIcon: "bg-[linear-gradient(152.06deg,_#906BAC_17.33%,_#734D99_100%)]",
    className: "text-black",
    permissions : 'Learners'
    //   permission: supplierServicePermission,
  },
  {
    title: "Contact Us",
    route: App_url.link.learnerContactUs,
    icon: App_url.image.Phone,
    classIcon: "bg-red-700",
    className : 'text-black',
    permissions : 'Learners'
  },

  {
    title: "Learners",
    route: App_url.EmployerPanelLink.Learner,
    icon: App_url.image.Learner,
    classIcon: "bg-[linear-gradient(270deg,_var(--primary-300)_5.71%,_var(--primary-400)_62.77%)]",
    className: "bg-[linear-gradient(270deg,_rgba(81,_114,_231,_0)_5.71%,_var(--primary-400)_62.77%)]",
      // permission: dashboardPermission,
  },
  {
    title: "Notifications",
    route: App_url.EmployerPanelLink.Notifications,
    icon: App_url.image.NotifyIcon,
    classIcon: "bg-[linear-gradient(152.06deg,_#906BAC_17.33%,_#734D99_100%)]",
    className: "bg-[linear-gradient(270deg,_rgba(81,_114,_231,_0)_5.71%,_#734D99_62.77%)]"
    //   permission: supplierServicePermission,
  },
  {
    title: "Contact Us",
    route: App_url.EmployerPanelLink.ContactUS,
    icon: App_url.image.Phone,
    classIcon: "bg-[linear-gradient(152.06deg,_#F09449_17.33%,_#FC883F_100%)]",
    className: "bg-[linear-gradient(270deg,_rgba(81,_114,_231,_0)_5.71%,_#FC883F_62.77%)]"
  },

  {
    title: "Learners",
    route: App_url.TutorPanelLink.Learner,
    icon: App_url.image.Learner,
    classIcon: "bg-[linear-gradient(270deg,_var(--primary-300)_5.71%,_var(--primary-400)_62.77%)]",
    className: "bg-[linear-gradient(270deg,_rgba(81,_114,_231,_0)_5.71%,_var(--primary-400)_62.77%)]"
    //   permission: dashboardPermission,
  },
  {
    title: "Delivery",
    route: App_url.TutorPanelLink.Delivery,
    icon: App_url.image.Delivery,
    classIcon: "bg-[linear-gradient(152.06deg,_#66C6E4_17.33%,_#408595_100%)]",
    className: "bg-[linear-gradient(270deg,_rgba(81,_114,_231,_0)_5.71%,_#408595_62.77%)]"
  },
  {
    title: "Notifications",
    route: App_url.TutorPanelLink.Notifications,
    icon: App_url.image.NotifyIcon,
    classIcon: "bg-[linear-gradient(152.06deg,_#906BAC_17.33%,_#734D99_100%)]",
    className: "bg-[linear-gradient(270deg,_rgba(81,_114,_231,_0)_5.71%,_#734D99_62.77%)]"
    //   permission: supplierServicePermission,
  },
  {
    title: "Contact Us",
    route: App_url.TutorPanelLink.ContactUS,
    icon: App_url.image.Phone,
    classIcon: "bg-[linear-gradient(152.06deg,_#F09449_17.33%,_#FC883F_100%)]",
    className: "bg-[linear-gradient(270deg,_rgba(81,_114,_231,_0)_5.71%,_#FC883F_62.77%)]"
  },

  {
    title: "Learners",
    route: App_url.IQAPanelLink.ViewBasicDetails,
    icon: App_url.image.Learner,
    classIcon: "bg-[linear-gradient(270deg,_var(--primary-300)_5.71%,_var(--primary-400)_62.77%)]",
    className: "bg-[linear-gradient(270deg,_rgba(81,_114,_231,_0)_5.71%,_var(--primary-400)_62.77%)]"
    //   permission: dashboardPermission,
  },
  {
    title: "Delivery",
    route: App_url.IQAPanelLink.DeliveryFlow,
    icon: App_url.image.Delivery,
    classIcon: "bg-[linear-gradient(152.06deg,_#66C6E4_17.33%,_#408595_100%)]",
    className: "bg-[linear-gradient(270deg,_rgba(81,_114,_231,_0)_5.71%,_#408595_62.77%)]"
  },
  {
    title: "Contact Us",
    route: App_url.IQAPanelLink.ContactUS,
    icon: App_url.image.Phone,
    classIcon: "bg-[linear-gradient(152.06deg,_#F09449_17.33%,_#FC883F_100%)]",
    className: "bg-[linear-gradient(270deg,_rgba(81,_114,_231,_0)_5.71%,_#FC883F_62.77%)]"
  },
];
export const Variant = {
  outline: {
    className: "border bg-transparent font-[600]",
    icon: "s"
  },
  "primary": {
    className: "border border-primary bg-primary text-white lg:text-xsm 2xl:text-md font-[600]",
    icon: "white"
  },
  "primary-1": {
    className: "border border-primary-1 bg-primary-1 text-white lg:text-xsm 2xl:text-md font-[600] rounded-sm",
    icon: "white"
  },
}


export const userRecords = [
  { email: 'appristine@gmail.com', password: 'Pass@123' ,role :'Admin' , access_token : 'appristine123' , is_admin : true ,  role_permissions : ['/learners' , '/employee' , '/tutor'] },
  { email: 'ajit@appristine.in', password: 'Pass@123' ,role :'Learner' , access_token : 'appristine123' ,  is_admin : false , role_permissions : ['/learners'] },
  { email: 'm.aniket@appristine.in', password: 'Pass@123' , role : '' , access_token : 'appristine222' },
  { email: 'khushbu@appristine.in', password: 'Pass@123' , role : '' , access_token : 'appristine3' },
  { email: 'raju@appristine.in', password: 'Pass@123' , role : '' , access_token : 'appristine4' },
  { email: 'shubham@appristine.in', password: 'Pass@123' , role : '' , access_token : 'appristine5' },
];