/* eslint-disable */
import React, { createContext, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { NavigateFunction, useLoaderData, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import io from "socket.io-client";
import { Dispatch, UnknownAction } from "@reduxjs/toolkit";
import { handleSocketResponse } from "./WebSocketResponse";
import App_url from "../Components/constants/static";
import { usePosterReducers } from "../redux/usePoster";
import PreviewModal from "../Components/modal/PreviewModal";
import { IShowModalPopup } from "../redux/modules/request/types";


interface WebSocketContextType {
  socket: any | null;
  send: (data: any) => void;
  receivedMessage: any | null;
  setPreviewPopup: (data: IShowModalPopup) => void;
  isConnect: boolean;
  PreviewPopup?: IShowModalPopup | null
}

export const WebSocketContext = createContext<WebSocketContextType | undefined>(
  undefined
);

export const useWebSocket = () => {
  const context = useContext(WebSocketContext);
  if (!context) {
    throw new Error("useWebSocket must be used within a WebSocketProvider");
  }
  return context;
};
export const useModalData = () => {
  const context = useContext(WebSocketContext);
  const posterData = usePosterReducers();
  if (!context) {
    throw new Error("useWebSocket must be used within a WebSocketProvider");
  }
  return {...context, ...posterData};
};
export const CommonResponse = (data:any) => {
  if (data?.errors?.length > 0) {
    data?.errors?.map((item:any, index:number) => {
      if (item?.message) {
        toast.error(item?.message);
      }
    });
  } else {
    toast.error(data?.msg);
  }
};

export const WebSocketProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [PreviewPopup, setPreviewPopup] = useState<IShowModalPopup>({
    title:"",
    name:"",
    description:"",
    data:"",
    show:"",
    buttonSuccess:"",
    buttonCancel:"",
  })
  const [socket, setSocket] = useState<any | null>(null);
  const [receivedMessage, setReceivedMessage] = useState<any | null>(null);
  const [isConnect, setIsConnect] = useState<boolean>(false);
  const { user_data } = usePosterReducers();

  const dispatch:any = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const send = (data: any) => {
    console.log("Send ::",data);
    
    const api_permissions = user_data?.user?.api_permissions;
    
    if(user_data?.user?.role === "admin"  || (api_permissions?.includes(`${data?.type}:${data?.action}`)) ){
      if (socket && socket.connected) {
        const payloadData = {
          ...data,
        }
        if(data?.payload?.query){
          payloadData.payload.query = data?.payload?.query.trim();
        }
        // if(data?.payload?.to_date){
        //   payloadData.payload.to_date = formatDate2(data?.payload?.to_date)
        // }
        if (isConnect) {
          socket.emit("action", payloadData);
        }
      } else {
        console.error("Socket.io connection not open");
      }
    }
  };

  const connectSocket = () => {
    const socket = io(`${process.env.REACT_APP_ENDPOINT_URL}`, {
      auth: { token: user_data?.access_token },
    });

    socket.on("connect", () => {
      console.log("Socket.io connected");
      setIsConnect(true);
      setSocket(socket);
    });

    socket.on("disconnect", () => {
      console.log("Socket.io disconnected");
      setIsConnect(false);
      // dispatch(setSocketStatus(false))
      setSocket(null);
    });

    socket.on("connect_error", (error) => {
      console.error("Socket.io error:", error);
      setIsConnect(false);
      // Implement exponential backoff for reconnection
      setTimeout(() => {
        connectSocket(); // Retry connecting after some delay
      }, 5000); // Adjust delay as needed
    });

    socket.on("data",async (data: any) => {
      console.log("Socket.io message ::", data);
      if (data?.msg === "unauthorized") {
        // dispatch(setLogout());
        navigate(App_url.link.SignIn);
      }
      if (data?.status === false) {
        CommonResponse(data);
      }
      
      await dispatch(await handleSocketResponse("data", data, dispatch, send, navigate, user_data));
      
    });

    return socket;
  };

  // useEffect(() => {
  //   let socket: any | null = null;

  //   if(user_data?.is_Login) {
  //     socket = connectSocket();
  //     const reconnectInterval = setInterval(() => {
        
  //       if (user_data?.access_token) {
  //         if (!socket || !socket.connected) {
  //           socket = connectSocket();
  //         }
  //       } else {
  //         navigate(App_url.link.Dashboard);
  //       }
  //     }, 5000);

  //     return () => {
  //       clearInterval(reconnectInterval);
  //       if (socket) {
  //         socket.disconnect();
  //       }
  //     };
  //   }
  // }, [user_data?.access_token, dispatch]);

  const contextValue: WebSocketContextType = {
    socket: socket,
    send: send,
    receivedMessage: receivedMessage,
    isConnect: isConnect,
    PreviewPopup: PreviewPopup,
    setPreviewPopup: setPreviewPopup
  };

  return (
    <WebSocketContext.Provider value={contextValue}>
      {children}
      <PreviewModal />
    </WebSocketContext.Provider>
  );
};
