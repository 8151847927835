import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Review {
  date: string;
  time: string;
  particular: string;
  action: string;
}

export interface ReviewsState {
  Review?: Review;
  headings: string[];
  reviews: Review[];
}

const initialState: ReviewsState = {
  headings: ['Date', 'Time', 'Particular', 'Action'],
  reviews: [
    {
      date: '28/8/2024',
      time: '11:00',
      particular: '12 Weekly review has been shared by admin please check.',
      action: 'Check',
    },
    {
      date: '28/8/2024',
      time: '11:00',
      particular: '12 Weekly review has been shared by admin please check.',
      action: 'Check',
    },
  ],
};

const reviewsSlice = createSlice({
  name: 'reviews',
  initialState,
  reducers: {
    addReview: (state, action: PayloadAction<Review>) => {
      state.reviews.push(action.payload);
    },
    removeReview: (state, action: PayloadAction<number>) => {
      state.reviews.splice(action.payload, 1);
    },
  },
});

export const { addReview, removeReview } = reviewsSlice.actions;

export default reviewsSlice.reducer;
